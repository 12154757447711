import { Route, Routes } from "react-router-dom";
import "./App.css";
import NavigationBar from "./basic_components/navigation_bar/navigation_bar";
import AboutMe from "./pages/aboutme/aboutme";
import ContactMe from "./pages/contactme/contactme";
import Home from "./pages/home/home";
import ArticleView from "./pages/articleview/articleview";

function App() {
  return (
    <div className="app">
      <NavigationBar />
      <div className="w-5/6 md:w-3/4 lg:w-2/4 mx-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/contactme" element={<ContactMe />} />
          <Route path="/article/:id" element={<ArticleView />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
