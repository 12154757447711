import { Link } from "react-router-dom";

function ArticleCard(props) {
  return (
    <div className="container mx-auto mt-4">
      <div className="relative flex h-full flex-col rounded-md border border-gray-200 bg-white p-2.5 hover:bg-gray-100 sm:rounded-lg sm:p-5 divide-y divide-double">
        <span className="text-lg font-semibold text-gray-900 hover:font-bold sm:mb-1.5 sm:text-xl">
          <Link to={`article/${props.id}`}>{props.title}</Link>
        </span>
        <div className="flex space-x-8 items-center py-1">
          <div className="flex items-center">
            <i className="fas fa-clock mr-2"></i>
            <span>{props.read_time} mins</span>
          </div>
          <div className="flex items-center">
            <i className="fas fa-calendar-alt mr-2"></i>
            <span>{(new Date(props.published_at)).toDateString()}</span>
          </div>
          <div className="flex items-center">
            <i className="fas fa-file-word mr-2"></i>
            <span>1200 words</span>
          </div>
        </div>
        <span className="text-md text-gray-900 line-clamp-5 py-1">
          {props.excerpt}
        </span>
      </div>
    </div>
  );
}

export default ArticleCard;
