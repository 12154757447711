import { Link, useResolvedPath, useMatch } from "react-router-dom";
import $ from "jquery";
import "./navigation_bar.css";

$(document).ready(function () {
  $(".nav-toggler").each(function (_, navToggler) {
    var target = $(navToggler).data("target");
    $(navToggler).on("click", function () {
      $(target).animate({
        height: "toggle",
      });
    });
  });
});

function NavigationBar() {
  return (
    <div className="navigationbar">
      <nav className="flex items-center bg-gray-800 p-3 flex-wrap">
        <a href="/" className="p-2 mr-4 inline-flex items-center">
          <span className="text-xl text-white font-bold uppercase tracking-wide">
            ChaiTalks.tech
          </span>
        </a>
        <button
          className="text-white inline-flex p-3 hover:bg-gray-900 rounded lg:hidden ml-auto hover:text-white outline-none nav-toggler"
          data-target="#navigation"
        >
          <i className="material-icons">menu</i>
        </button>
        <div
          className="hidden top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto"
          id="navigation"
        >
          <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
            <CustomPage toPage="/">Home</CustomPage>
            <CustomPage toPage="/aboutme">About Me</CustomPage>
            <CustomPage toPage="/contactme">Contact Me</CustomPage>
          </div>
        </div>
      </nav>
      <div className="navigationbar_logo">
        <img
          className="size-24 lg:size-44 rounded-full mx-auto"
          src="/logo.png"
          alt="logo"
        />
      </div>
    </div>
  );
}

function CustomPage({ toPage, children }) {
  const resolvePath = useResolvedPath(toPage);
  const isActive = useMatch({ path: resolvePath.pathname, end: true });
  return (
    <Link
      to={toPage}
      className={`lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-400 items-center justify-center hover:bg-gray-900 hover:text-white${isActive ? " font-bold" : ""}`}
    >
      {children}
    </Link>
  );
}

export default NavigationBar;
