function ContactMe(){
    return (
        <div className="contactme">
            <h1 className="text-3xl font-bold underline">Contact Me</h1>
            <p>
                You can contact me on <a href="https://www.linkedin.com/in/phani-chaitanya-pendyala-88327bba/">LinkedIn</a>
            </p>
        </div>
    )
}

export default ContactMe;
