import ArticleCard from "../../basic_components/article_card/article_card";
import axios from "axios";
import { useEffect, useState } from "react";

function Home() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios
      .get("https://chaitalks-backend-dbxn3.ondigitalocean.app/articles/")
      .then((res) => {
        const allArticles = res.data;
        setArticles(allArticles);
      });
  }, []);

  return (
    <div className="home">
      {articles.map((article) => (
        <ArticleCard
          key={article.id}
          id={article.id}
          title={article.title}
          read_time={article.read_time}
          excerpt={article.excerpt}
          published_at={article.published_at}
        />
      ))}
    </div>
  );
}

export default Home;
