import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import axios from "axios";
import './articleview.css';


function ArticleView() {
  const { id } = useParams();
  const [article, setArticle] = useState({})

  useEffect(() => {
    console.log(id);
    axios
      .get(`https://chaitalks-backend-dbxn3.ondigitalocean.app/articles/${id}`)
      .then((res) => {
        const article = res.data;
        setArticle(article);
      });
  }, [id]);

  return (
    <div className="prose">
      <ReactMarkdown>{article.content}</ReactMarkdown>
    </div>
  );
}

export default ArticleView;
