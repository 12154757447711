function AboutMe(){
    return (
        <div className="aboutme">
            <h1 className="text-3xl font-bold underline">About Me</h1>
            <p>
                My name is Phani Chatianya. I am a software developer.
            </p>
        </div>
    )
}

export default AboutMe;
